:root {
  --background: #f5f6f7;
  --background-utils: #fff;
  --foreground: #111c23;
  --foreground-blur: linear-gradient(
    rgba(17, 28, 35, 0.05),
    rgba(255, 255, 255, 0)
  );
  --info-box:#8ad0ff21;
  --basic: #005eff;
  --a-primary: #005eff;
  --primary: #005eff;
  --primary-dark: #0258d2;
  --primary-button: #005eff;
  --primary-button-hover: #0258d2;
  --primary-icon: var(--primary);
  --primary-button-a: white;
  --outlined: #005eff;
  --shinyBlue: #0472f5;
  --outlinedHover: rgba(0, 0, 0, 0.05);
  --accent: #00e4b8;
  --accent-dark: #00c4b0;
  --white: #fff;
  --black: #000;
  --success: #60c128;
  --info: #33a9f7;
  --warning: #ffc014;
  --error: #ff3f35;
  --blue-marine: linear-gradient(180deg, var(--primary), #0097ad);
  --blue-marine-lighter: linear-gradient(180deg, #14f1d9, var(--primary));
  --bg-gradient:linear-gradient(270deg, #0243c6 -3.3%, #005eff 98.41%);
  --bg-gradient-reverse:linear-gradient(200deg, #0AB9FA -3.3%, #4185EB 98.41%);
  --grey: #263c4b;
  --grey-dark: #111c23;
  --grey-light: #637483;
  --grey-lighter: #92a0ae;
  --grey-list: #f4f6fc;
  --grey-tabs: #e9e9e9;
  --card-border: #dfe1e6;
  --border-sidemanu:var(--card-border);
  --card-border-box:#dfe1e6;
  --card-border-pricing: #EBEBEB;
  --bg-aside: linear-gradient(180deg, #0044b4 0%, #0243c6 100%);
  --bg-book-call: #161a42;
  --enterprise: var(--bg-book-call);
  --bg-sidenav: #fff;
  --active-icon: var(--primary);
  --input: #111c23;
  --input-border: #1ea5ff;
  --activeMenu: #005eff;
  --tab: #005eff;
  --th: #E6F2FF;
  --tb-clicked: #ddd9;
  --group-row: var(--background-utils);
  --input-hover: #ddd3;
  --tab-hover: #005eff25;
  --tr-clicked: #ddd6;
  --tr-zebra: #f6f9fc;
  --modal: var(--grey-list);
  --bg-opacity: rgba(255, 255, 255);
  --background-subheader: #ffffffe8;
  --button-loading: rgba(0, 94, 255, 0.6);
  --bg-login: url('/images/Logo.png'), var(--bg-aside);
  --logo-login: url('/images/logo/bdroppy-logo-black.svg');
  --a-secondary: var(--primary);
  --t-head: #e9eff7;
  --t-row: #e9eff78e;
  --tab-head: #ddd;
  --tab-head-active: #fff;
  --hover-list: #e2edff;
  --import-action: var(--grey-dark);
  --li-sidenav: var(--grey-dark);
  --li-sidenav-active: #f3f7ff;
  --user-menu: rgba(15, 14, 71, 1);
  --icon-menu: var(--grey-dark);
  --select-all: var(--primary);
  --template-cta: var(--primary);
  --bg-most-popular: linear-gradient(89.77deg, #f1f6ff 51.44%, #d9e7ff 102.28%);

  --shadow-light: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  --shadow-light-tab: 0px 0px 5px rgba(23, 24, 24, 0.01),
    0px -1px 2px rgba(0, 0, 0, 0.08);
  --shadow-light-hover: 0px 10px 20px rgba(0, 0, 0, 0.1);
  --shadow-blue: 0px 2px 6px rgba(4, 114, 245, 0.25);
  --shadow-green: 0px 2px 6px rgba(0, 228, 184, 0.25);
  --shadow-input: 0px 0px 4px rgba(4, 114, 245, 0.45);

  --pd-grid: 32px 24px;
  --pd-filter: 8px 0px;

  --margin-xs: 3px 6px;
  --margin-sm: 8px;
  --margin-md: 16px;
  --margin-l: 32px;
  --margin-xl: 40px;

  --radius-sm: 2px;
  --radius: 5px;
  --radius-md: 8px;
  --radius-lg: 10px;
  --radius-xl: 20px;

  --border: solid thin var(--grey);
  --line: 1px solid #dfe1e6;
  --line-header: 1px solid #dfe1e6;
  --banner-top: #feecdb;
  --top-banner-p:#111C23;
  --dark-line: 1px solid #e4e4e4;
  --disabled: #a4a4a4;
  --disable-bg: #e6e6e6;
  --container-width: 100%;
  --breadcrumb-grey: #92a0ae;
  --breadcrumb-black: #263c4b;
  --step-grey: #a5a5a5;
  --step-black: #263c4b;
  --step-blue: #005eff;
  --step-dark-blue: #0044b9;
  --step-transition: 0.5s ease-in-out;

  --table-border: #92a0ae;
  --table-body-border: #f0f0f0;

  --underline-cta: #000;
  --bg-wrapper-info: #e6f2ff;
  --enterprise-color: #00596d;
  --plus-color: #093176;
  --basic-color: var(--primary);
  --bg-image:white;
  --bg-social:#EDF0FF;
  --social-button:linear-gradient(274.49deg,#FF006C 9.28%,#FDBD38 104.34%);
  --bg-selector:var(--grey-dark);
  --bg-mobile-footer:var(--grey);
  --text-new:#cd0057;
  --bsocial-color:#cd0057;
  --tab-inactive-pricing:#dfe1e6;
  --hover-sidenav-li:#ddd5;
  --hover-sidenav-border:#f4f4f4;
  --background-catalog:#e6f2ff;
  --upgrade-background:#f5f6f7;
  --background-catalog-img:#e6f2ff;
  --background-sticky:var(--background-utils);
  --bg-shopify-section:#e6f2ffd1;
  --sort-background:var(--background-utils);
  --switch-background:var(--card-border);
  --border-tab:solid thin #ccc4;
  --title-sidenav:var(--grey-light);
  --border-update:#c5d9ec;
  --ribbon-span:#010101;
  --background-home:var(--background);
  --background-home-2:var(--background-utils);
  --border-card-home:#fff8;
  --bg-gradient-reseller:linear-gradient( 112.03deg, #fff3 2.08%, #fff3 6.67%, #fff4 6.68%, #fff7 100% );
  --bg-gradient-affiliation:linear-gradient( 112.03deg, #fff3 2.08%, #fff3 6.67%, #fff5 6.68%, #fff6 100% );
  --background-content-price:#fff8;
}

[data-theme='dark'] {
  --background: #161b23;
  --background-utils: #212b36;
  --foreground: #f6f8fe;
  --foreground-blur: linear-gradient(
    rgba(246, 248, 254, 0.05),
    rgba(255, 255, 255, 0)
  );
  --primary:var(--accent);
  --info-box:#33a9f721;
  --error:#ff655d;
  --border: solid thin var(--foreground);
  --bg-aside: var(--background-utils);
  --bg-sidenav: var(--background-utils);
  --li-sidenav-active: #02dab014;
  --import-action: var(--background-utils);
  --active-icon: var(--accent);
  --line: 0.2px solid #33485d49;
  --line-header: 0.4px solid #5d687449;
  --input: #f6f8fe;
  --grey-tabs: #0d1224;
  --card-border: var(--background-utils);
  --card-border-box: #2b91c48a;
  --grey-light: #909294;
  --tab: #fff;
  --banner-top: #feecdb;
  --breadcrumb-grey: #92a0ae;
  --breadcrumb-black: #fff;
  --step-grey: #a5a5a5;
  --step-black: #fff;
  --tab-head: #0d1224;
  --tab-head-active: #222b45;
  --top-banner-p:#111C23;
  --background-subheader: #161b23d1;
  --logo-login: url('/images/logo/bdroppy-logo-white.svg');
  --th: #545c64 ;
  --input-hover: rgba(255, 255, 255, 0.1);
  --disabled: #a4a4a4;
  --disable-bg: #fff5;
  --outlined: #f6f8fe;
  --outlinedHover: rgba(255, 255, 255, 0.1);
  --a-secondary: var(--accent);
  --t-head: #0d122499;
  --t-row: #0d12245d;
  --tb-clicked: #394550;
  --group-row: #28333f;
  --enterprise: #161a42;
  --tr-zebra: #33485d86;
  --user-menu: rgba(231, 231, 231, 1);
  --bg-opacity: var(--background);
  --bg-login: url('/images/Logo_black.png'), var(--bg-aside);
  --bg-book-call: var(--bg-aside);
  --hover-list: #161616;
  --icon-menu: -webkit-linear-gradient(var(--accent), #02c6ac);
  --li-sidenav: #f3f7ff;
  --underline-cta: #fff;
  --card-border-pricing: #3e5768;
  --primary-button: #fff;
  --primary-button-hover: #fff;
  --primary-button-a: var(--grey-dark);
  --primary-icon: var(--accent);
  --bg-wrapper-info: var(--background);
  --select-all: var(--primary);
  --template-cta: #fff;
  --a-primary: var(--accent);
  --enterprise-color: #009ec2;
  --basic-color: #1986ff;
  --bg-gradient:linear-gradient(270deg, #0243c6 -3.3%, #005eff 98.41%);
  --title-sidenav:var(--grey-lighter);
  --plus-color: #0243c6;
  --bg-image:#e6f2ff;
  --bg-social:var(--background);
  --bg-selector:white;
  --bg-mobile-footer:white;
  --text-new:#fff;
  --bsocial-color:white;
  --hover-sidenav-li:#ddd2;
  --hover-sidenav-border:#3a424c;
  --tab-inactive-pricing:var(--background);
  --background-catalog:var(--background);
  --upgrade-background:var(--background);
  --background-catalog-img:white;
  --background-sticky:var(--background);
  --bg-shopify-section:var(--background);
  --sort-background:#0e1116;
  --switch-background:var(--sort-background);
  --step-blue: var(--accent);
  --step-dark-blue: var(--accent-dark);
  --border-update:#919191;
  --ribbon-span:#ed9dfc;
  --background-home:var(--background-utils);
  --background-home-2:var(--background);
  --bg-gradient-reseller:linear-gradient( 112.03deg, #161b2338 2.08%, #0002 6.67%, #161b2338 6.68%, #0004 100% );
  --bg-gradient-affiliation:linear-gradient( 112.03deg, #161b2338 2.08%, #0002 6.67%, #161b2338 6.68%, #0004 100% );
  --border-card-home:#fff3;
  --border-sidemanu:var(--border-card-home);
  --background-content-price:#212b36;
}

/* Mobile variables */
:root {
  --container-width: 100%;
  --card-padding: 0.7rem;
}
@media screen and (min-width: 576px) {
}

@media screen and (min-width: 768px) {
  /* Tablet variables */
  :root {
    --container-width: 750px;
    --card-padding: 1rem;
  }
}
@media screen and (min-width: 992px) {
  /* Desktop variables */
  :root {
    --container-width: 970px;
  }
}
@media screen and (min-width: 1200px) {
  /* Large desktop variables */
  :root {
    --container-width: 1170px;
  }
}
